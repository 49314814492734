var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "userManagement", staticClass: "user-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onAddUser },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "72px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户姓名：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户姓名" },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名" },
                    model: {
                      value: _vm.form.loginName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "loginName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      "company-tree": _vm.companyList,
                      valuename: _vm.valuename,
                      value: _vm.value,
                    },
                    on: { getValue: _vm.getGroupId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "禁用", value: 1 } }),
                      _c("el-option", { attrs: { label: "启用", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          "col-class-type": true,
          operation: true,
          "table-height": _vm.tableHeight,
          "operation-width": "160",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    scope.scopeRow.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onUpdateUser(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "small",
                          sort:
                            scope.scopeRow.status === 0 ? "danger" : "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDisableUser(scope.scopeRow)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.scopeRow.status === 0 ? "禁用" : "启用")
                        ),
                      ]
                    ),
                    scope.scopeRow.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onResetPassword(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("重置密码")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onDialogClose,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "82px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户姓名：", prop: "userName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户姓名" },
                          model: {
                            value: _vm.addForm.userName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名：", prop: "loginName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户名" },
                          model: {
                            value: _vm.addForm.loginName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "loginName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.loginName",
                          },
                        }),
                      ],
                      1
                    ),
                    this.title.indexOf("新增") !== -1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "密码：", prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入密码" },
                              model: {
                                value: _vm.addForm.password,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "password",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.password",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号码：", prop: "phone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号码" },
                          model: {
                            value: _vm.addForm.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属公司：", prop: "companyId" } },
                      [
                        _c("companySelect", {
                          attrs: {
                            clearable: true,
                            "company-tree": _vm.companyList,
                            valuename: _vm.valueAddName,
                            value: _vm.valueAdd,
                          },
                          on: { getValue: _vm.getAddGroupId },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading,
                  },
                  on: { click: _vm.onSave },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }