<template>
  <div ref="userManagement" class="user-management">
    <div ref="functionButtons" class="function-buttons">
      <el-button type="primary" size="small" @click="onAddUser">新增</el-button>
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        label-position="left"
        label-width="72px"
        class="demo-form-inline"
      >
        <el-form-item label="用户姓名：">
          <el-input
            v-model.trim="form.userName"
            placeholder="请输入用户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input
            v-model.trim="form.loginName"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="form.status" clearable placeholder="请选择状态">
            <el-option label="禁用" :value="1"></el-option>
            <el-option label="启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :table-height="tableHeight"
      operation-width="160"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="scope.scopeRow.status === 0"
            type="text"
            size="small"
            sort="primary"
            @click="onUpdateUser(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
            @click="onDisableUser(scope.scopeRow)"
            >{{ scope.scopeRow.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <el-button
            v-if="scope.scopeRow.status === 0"
            type="text"
            size="small"
            sort="danger"
            @click="onResetPassword(scope.scopeRow)"
            >重置密码</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增|编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      @close="onDialogClose"
    >
      <div>
        <div class="body">
          <el-form
            ref="addForm"
            :model="addForm"
            :rules="rules"
            label-width="82px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="用户姓名：" prop="userName">
              <el-input
                v-model.trim="addForm.userName"
                placeholder="请输入用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户名：" prop="loginName">
              <el-input
                v-model.trim="addForm.loginName"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="this.title.indexOf('新增') !== -1"
              label="密码："
              prop="password"
            >
              <el-input
                v-model.trim="addForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="phone">
              <el-input
                v-model.trim="addForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属公司：" prop="companyId">
              <companySelect
                :clearable="true"
                :company-tree="companyList"
                :valuename="valueAddName"
                :value="valueAdd"
                @getValue="getAddGroupId"
              ></companySelect>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import {
  checkPhone,
  checkUsername,
  checkPassword,
  checkName,
} from "@/common/utils/index.js";

import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getUserList,
  disableUser,
  enableUser,
  resetPwd,
  insertUser,
  updateUser,
  getCompanyTree,
} from "@/api/lib/api.js";

export default {
  name: "userManagement",
  components: {
    companySelect,
  },
  data() {
    return {
      titleName: [
        {
          title: "用户姓名",
          props: "userName",
        },
        {
          title: "用户名",
          props: "loginName",
        },
        {
          title: "手机号码",
          props: "phone",
        },
        {
          title: "所属企业",
          props: "companyName",
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0 ? "启用" : "禁用";
          },
        },
      ],
      tableHeight: 0,
      tableData: [],
      value: null,
      valuename: null,
      valuenameMultiple: null,
      valueMultiple: null,
      valueAddName: null,
      valueAdd: null,
      form: {
        userName: null,
        loginName: null,
        companyId: null,
        roleId: null,
        status: null,
        currentPage: 1,
        pageSize: 10,
      },
      addForm: {
        id: "",
        type: 1,
        userName: "",
        loginName: "",
        password: "",
        phone: "",
        companyId: [],
        hdVideo: 0,
        wxStatus: 1,
        userGroupId: "",
        roleIds: [],
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        loginName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { validator: checkUsername, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },          
          { validator: checkPassword, trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码为6-18位数字或字母",
            trigger: "blur",
          },
        ],
        companyId: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
      },
      total: 0,
      dialogVisible: false,
      title: "",
      companyList: [],
      authCompanyList: [],
      availableUserGroupList: [],
      roleList: [],
      typeList: [],
      availableRoleList: [],
      searchLoading: false,
      editLoading: false,
      userId: null,
    };
  },

  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.userManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16;
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getDataList();
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getDataList();
    },
    // 新增用户
    onAddUser() {
      this.title = "新增用户";
      this.valueMultiple = [];
      this.valuenameMultiple = "";
      this.valueAdd = null;
      this.valueAddName = "";
      if (this.$refs.addForm) this.$refs.addForm.clearValidate();
      this.addForm.companyId = [];
      this.dialogVisible = true;
    },
    // 点击表格查看按钮
    onUpdateUser(row) {
      this.title = "修改用户信息";
      this.addForm = row;
      this.$nextTick(() => {
        if (this.$refs.addForm) this.$refs.addForm.clearValidate();
        this.valueAdd = row.companyId;
        this.valueAddName = row.companyName;
      });
      this.dialogVisible = true;
    },
    // 弹窗关闭时的回调
    onDialogClose() {
      this.valueMultiple = [];
      this.valuenameMultiple = "";
      this.valueAdd = null;
      this.valueAddName = "";
      this.addForm = {
        id: "",
        userName: "",
        loginName: "",
        password: "",
        phone: "",
        companyId: [],
      };
      this.$refs.addForm.clearValidate();
      this.dialogVisible = false;
      this.editLoading = false;
    },

    // 重置密码
    onResetPassword(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将重置用户 "),
          h("span", { style: "color: #059370" }, row.userName),
          h("span", null, "  的密码为"),
          h("span", { style: "color: #059370" }, "123456"),
          h("span", null, "，是否重置？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          resetPwd({ id: row.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "重置密码成功!",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消重置密码",
          });
        });
    },

    // 禁用|启用 用户
    onDisableUser(row) {
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用用户 "),
            h("span", { style: "color: #059370" }, row.userName),
            h("span", null, " ？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            disableUser({ id: row.id }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("禁用成功");
                row.status = 1;
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用",
            });
          });
      } else {
        enableUser({ id: row.id }).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            row.status = 0;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 获取用户列表
    getDataList() {
      getUserList(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
        this.searchLoading = false;
      });
    },
    // 新增/保存用户
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          let data = {
            userName: this.addForm.userName,
            loginName: this.addForm.loginName,
            phone: this.addForm.phone,
            companyId: this.addForm.companyId,
          };
          if (this.title.indexOf("新增") !== -1) {
            const password = md5(this.addForm.password);
            data.password = password;

            insertUser(data).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("新增成功");
                this.onSearch();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            data = {
              ...data,
              id: this.addForm.id,
              status: this.addForm.status,
            };
            updateUser(data).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("修改成功");
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          this.onDialogClose();
        }
      });
    },
    // 业务公司
    getGroupId(v) {
      this.form.companyId = [v];
    },
    // 企业回显
    getAddGroupId(v) {
      this.addForm.companyId = v;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree().then((res) => {
        if (res.code === "SUCCESS") {
          this.companyList = res.data;
        }
      });
    },
  },
  created() {
    this.onSearch();
    this.getCompanyList();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.form-area {
  margin-bottom: 14px;
}
.user-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .user-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>